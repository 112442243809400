import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Baby from "../../assets/Category/Baby.jpg";
import Beauty from "../../assets/Category/Beauty.jpg";
import Home from "../../assets/Category/HomeCare.jpg";
import Personal from "../../assets/Category/Personal.jpg";

const CategoryData = [
  {
    image: Beauty,
    discrption: "Beauty Care",
    link: "/product",
  },
  {
    image: Baby,
    discrption: "Baby care",
    link: "/product",
  },
  {
    image: Personal,
    discrption: "Personal health care",
    link: "/product",
  },
  {
    image: Home,
    discrption: "Home Care (Comfort & CIF)",
    link: "/product",
  },
];

export const Category = () => {
  return (
    <>
      <Container>
        <div className="Category-section">
          <div className="category-title">
            <h1 className="page-title">Category</h1>
          </div>
          <div>
            <Row>
              {CategoryData.map((card, index) => (
                <Col xl="6" lg="6" md="6" className="mobile-card">
                  {/* <div className="category-card">
                    <div className="category-body">
                      <img src={card.image} alt="" />
                    </div>
                  </div> */}
                  <div class="category-card">
                    <figure class="effect-zoe category-body">
                      <img src={card.image} alt="img25" />
                      <figcaption>
                        <h2>
                          {/* Creative <span>Zoe</span> */}
                          {card.discrption}
                        </h2>

                        {/* <p class="icon-links">
                          <a href="#">
                            <span class="icon-heart"></span>
                          </a>
                          <a href="#">
                            <span class="icon-eye"></span>
                          </a>
                          <a href="#">
                            <span class="icon-paper-clip"></span>
                          </a>
                        </p> */}
                        {/* <p class="description">
                          Zoe never had the patience of her sisters. She
                          deliberately punched the bear in his face.
                        </p> */}
                      </figcaption>
                      <figcaption className="hover">
                          <h1>
                            {/* Creative <span>Zoe</span> */}
                            {card.discrption} 
                          </h1>
                      </figcaption>
                    </figure>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};
