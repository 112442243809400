import React from "react";
import Carousel from "react-multi-carousel";
import { Col, Container, Row } from "react-bootstrap";
import Crest from "../../assets/Brand/Crest.png";
import Fairy from "../../assets/Brand/Fairy.png";
import Headandshoulders from "../../assets/Brand/headandshoulders.png";
import Herbalessences from "../../assets/Brand/herbal-essences.png";
import Oralb from "../../assets/Brand/oralb.png";
import Pamper from "../../assets/Brand/Pampers.png";
import Pantene from "../../assets/Brand/Pantene.png";
import {
  Axe,
  Cif,
  Comfort,
  Dove,
  Lifebuoy,
  Lux,
  Sunsilk,
  Vaseline,
  Rexona,
} from "../../assets";
const data = [
  {
    name: "Pampers",
    image: Pamper,
    brandName: "Baby Care",
    link: "https://www.pampers.com/en-us",
  },
  {
    name: "Head & Shoulders",
    image: Headandshoulders,
    brandName: "Beauty Care",
    link: "https://headandshoulders.com/en-us",
  },
  {
    name: "Herbal Essences",
    image: Herbalessences,
    brandName: "Beauty Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Axe",
    image: Axe,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Cif",
    image: Cif,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Comfort",
    image: Comfort,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Dove",
    image: Dove,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lifebuoy",
    image: Lifebuoy,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lux",
    image: Lux,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Sunsilk",
    image: Sunsilk,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Vaseline",
    image: Vaseline,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Rexona",
    image: Rexona,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Pantene",
    image: Pantene,
    brandName: "Beauty Care",
    link: "https://pantene.com/en-us",
  },
  {
    name: "Fairy",
    image: Fairy,
    brandName: "Home Care",
    link: "https://www.supersavvyme.co.uk/brands/fairy",
  },
  {
    name: "Crest",
    image: Crest,
    brandName: "Personal Health Care",
    link: "https://crest.com/en-us",
  },
  {
    name: "Oral-B",
    image: Oralb,
    brandName: "Personal Health Care",
    link: "https://oralb.com/",
  },
];

export const BrandCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="core-feature">
      <Container>
        <Row>
          <Col>
            <div className="core-feature-title">
              <h1 className="page-title ">Brands</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="brand_bg">
        <div className="core-feature-wrapper">
          <Container>
            <Row className="crousal-style">
              <Carousel
                ssr
                partialVisible
                removeArrowOnDeviceType={[]}
                itemClass="image-item"
                responsive={responsive}
                arrows={true}
                infinite={false}
                showDots={true}
                autoPlay={true}
                autoPlaySpeed={250000}
              >
                {data.map((dataCard) => {
                  return (
                    <div className="core-feature-card">
                      <div className="card-content">
                        <img
                          className="brand-image"
                          src={dataCard.image}
                          alt=""
                        />
                        <h3>{dataCard?.name}</h3>
                        <span>{dataCard?.brandName}</span>
                        <a
                          href={dataCard?.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="brand-site-button">Visit</button>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
