import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
  Divider,
  List,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Logo } from '../../assets';
import React from 'react';
import { Link } from 'react-router-dom';
import ListItemLink from '../ListItemLink/ListItemLink';
import HomeIcon from '@mui/icons-material/Home';
import ApiIcon from '@mui/icons-material/Api';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const navitems = [
  {
    title: 'Home',
    urlPath: '/',
    icon: HomeIcon,
  },
  {
    title: 'Brands',
    urlPath: '/brands',
    icon: ApiIcon,
  },
  // {
  //   title: "About Us",
  //   urlPath: "/about-us",
  //   icon: BubbleChartIcon,
  // },
  {
    title: 'Contact',
    urlPath: '/contact',
    icon: AlternateEmailIcon,
  },
];

export const Header = () => {
  const matches = useMediaQuery('(min-width:900px)');
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppBar position="sticky" elevation={0} className="navbar">
      <Toolbar className="toolbar">
        {matches ? (
          <>
            <Typography variant="h6" color="inherit" noWrap>
              <Link className="title link" to="/" color="inherit">
                <img src={Logo} alt="Logo" />
              </Link>
            </Typography>
            <Box mr={5}>
              {navitems.map((item) => {
                return (
                  <Link
                    className="nav-item"
                    color="textPrimary"
                    to={item.urlPath}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </Box>
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              style={{ display: 'flex' }}
            >
              <div style={{ marginRight: '10px', marginTop: '5px' }}></div>
              <Link to="/" className="link">
                <img src={Logo} alt="Logo" />
              </Link>
            </Typography>

            <Drawer
              onEscapeKeyDown={handleDrawerClose}
              onBackdropClick={handleDrawerClose}
              variant="temporary"
              anchor="left"
              open={open}
            >
              <div>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? (
                    <ChevronRightIcon className="chevron" />
                  ) : (
                    <ChevronLeftIcon className="chevron" />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                {navitems.map((item) => {
                  return (
                    <>
                      <ListItemLink
                        primary={item.title}
                        to={item.urlPath}
                        icon={item.icon}
                      />
                      <Divider />
                    </>
                  );
                })}
              </List>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
