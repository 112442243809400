import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Header, Footer } from "..";
import { Email, Phone, Location } from "../../assets";

export const ContactSection = () => {
  return (
    <>
      <Header />
      <div className="Contact-us-wrapper">
        <Container>
          <Row className="contact-row">
            <Col xl="4" lg="6" md="12">
              <div className="contact-address">
                <div className="contact-address-icon">
                  <img src={Email} alt="" />
                </div>
                <h3>Email address</h3>
                <p>abcd@gamil.com</p>
              </div>
            </Col>
            <Col xl="4" lg="6" md="12">
              <div className="contact-address">
                <div className="contact-address-icon">
                  <img src={Phone} alt="" />
                </div>
                <h3>Phone Number</h3>
                <p>9569589659</p>
              </div>
            </Col>
            <Col xl="4" lg="6" md="12">
              <div className="contact-address">
                <div className="contact-address-icon">
                  <img src={Location} alt="" />
                </div>
                <h3>Office Address</h3>
                <p>
                  HangingPanda Pvt. Ltd. B-47, Second floor, B Block, Sector 63,
                  Noida, Uttar Pradesh 201301{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="Contact">
        <Container>
          <Row>
            <Col lg="12">
              <div className="contact-form-box">
                <h4 className="title">Contact us</h4>

                <Form>
                  <Row>
                    <Col md="6">
                      <div className="ltn__custom-icon input-item ">
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter your name"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ltn__custom-icon input-item ">
                        <input
                          type="email"
                          className="input-box"
                          placeholder="Enter your email"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ltn__custom-icon input-item ">
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter your subject"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ltn__custom-icon input-item ">
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter phone number"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="ltn__custom-icon input-item ">
                    <textarea
                      name="message"
                      className="input-box textarea-message"
                      placeholder="Enter message"
                    />
                  </div>
                  <div class="btn-wrapper mt-0">
                    <button class="buttons text-uppercase" type="submit">
                      submit
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
