import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Tick from "../../assets/success.png";
import Customer from "../../assets/whysection/customer.png";
import Available from "../../assets/whysection/goods-available.png";
import PesticideFree from "../../assets/whysection/pesticide-free.png";
export const WhyChoseUs = () => {
  return (
    <>
      <div className="whyChosesection">
        <Container>
          <Row>
            <Col className="Choose-us-section">
              <div className="section-title">
                <h1 className="page-title">Why Choose Us</h1>
              </div>
            </Col>
          </Row>
          <div className="why-choose-us-container">
            <div className="left">
              <h1>
                What <span>We Serve:</span>
              </h1>
              <p>
                Lorem ipsum dolor sit ame it, consectetur adipisicing elit, sed
                do eiusmod te mp or incididunt ut labore.Lorem ipsum dolor sit
                ame it, consectetur adipisicing elit, sed do eiusmod te mp or
                incididunt ut labore.Lorem ipsum dolor sit ame it, consectetur
                adipisicing elit, sed do eiusmod te mp or incididunt ut labore.
              </p>
              <div className="list-section">
                <p className="list">
                  <img src={Tick} alt="" />{" "}
                  <span> Lorem ipsum dolor sit ame it, consectetur</span>
                </p>
                <p>
                  <img src={Tick} alt="" />{" "}
                  <span>Lorem ipsum dolor sit ame it, consectetur</span>
                </p>
                <p>
                  <img src={Tick} alt="" />{" "}
                  <span>Lorem ipsum dolor sit ame it, consectetur</span>
                </p>
              </div>
            </div>
            <div className="right">
              <Row className="side-cards">
                <Col lg="4" sm="12">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-img">
                        <img src={Customer} alt="" />
                      </div>
                      <h3 className="animated">Curated Products</h3>
                    </div>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-img">
                        <img src={Available} alt="" />
                      </div>
                      <h3 className="animated">All Kind Brand</h3>
                    </div>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-img">
                        <img src={PesticideFree} alt="" />
                      </div>
                      <h3 className="animated">Pesticide Free Goods</h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
