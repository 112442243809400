import { ListItem, ListItemText, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";


const ListItemLink = ({ primary, to, icon }) => {
  console.log(icon, "icon");
  const Icon = icon;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} icon={Icon} {...linkProps} style={{paddingLeft:'0', paddingRight:'6rem'}} />
      )),
    [to, Icon]
  );

  return (
    <li >
      <ListItem button component={CustomLink}>
        <IconButton>
          <Icon style={{color:"#d5681f"}} />
        </IconButton>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};
export default ListItemLink;