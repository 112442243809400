import React from "react";
import {
  Banner,
  Footer,
  Header,
  CoreFeatures,
  AboutProduct,
  Category,
  WhyChoseUs,
  BrandCarousel,
} from "../../components";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Category />
      <BrandCarousel />
      <AboutProduct />
      <WhyChoseUs />
      <CoreFeatures />

      <Footer />
    </>
  );
};

export default Home;
