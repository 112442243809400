import React from "react";
import Carousel from "react-multi-carousel";
import Crest from "../../assets/Brand/Crest.png";
import Fairy from "../../assets/Brand/Fairy.png";
import Headandshoulders from "../../assets/Brand/headandshoulders.png";
import Herbalessences from "../../assets/Brand/herbal-essences.png";
import Oralb from "../../assets/Brand/oralb.png";
import Pamper from "../../assets/Brand/Pampers.png";
import Pantene from "../../assets/Brand/Pantene.png";
import {
  Axe,
  Cif,
  Comfort,
  Dove,
  Lifebuoy,
  Lux,
  Sunsilk,
  Vaseline,
  Rexona,
} from "../../assets";
import { Container } from "react-bootstrap";

const data = [
  {
    name: "Pampers",
    image: Pamper,
    brandName: "Baby Care",
    link: "https://www.pampers.com/en-us",
  },
  {
    name: "Head & Shoulders",
    image: Headandshoulders,
    brandName: "Beauty Care",
    link: "https://headandshoulders.com/en-us",
  },
  {
    name: "Herbal Essences",
    image: Herbalessences,
    brandName: "Beauty Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Axe",
    image: Axe,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Cif",
    image: Cif,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Comfort",
    image: Comfort,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Dove",
    image: Dove,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lifebuoy",
    image: Lifebuoy,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lux",
    image: Lux,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Sunsilk",
    image: Sunsilk,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Vaseline",
    image: Vaseline,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Rexona",
    image: Rexona,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Pantene",
    image: Pantene,
    brandName: "Beauty Care",
    link: "https://pantene.com/en-us",
  },
  {
    name: "Fairy",
    image: Fairy,
    brandName: "Home Care",
    link: "https://www.supersavvyme.co.uk/brands/fairy",
  },
  {
    name: "Crest",
    image: Crest,
    brandName: "Personal Health Care",
    link: "https://crest.com/en-us",
  },
  {
    name: "Oral-B",
    image: Oralb,
    brandName: "Personal Health Care",
    link: "https://oralb.com/",
  },
];

export const Banner = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 768, min: 450 },
      items: 2,
    },
    smallMobile: {
      breakpoint: { max: 450, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="bannerWapper">
        {/* <img src={background} alt="" /> */}
        <div className="heading-wrapper">
          <h1> Our Faithful Partners Get Discount In All Products</h1>
          <p>
            Adipisicing aliqua consectetur sint mollit aliquip amet quis sint.
            Fugiat eu tempor elit est cupidatat quis ut culpa nisi duis
            cupidatat tempor. Aliqua fugiat voluptate minim consequat. Aute ut
            deserunt ad incididunt do amet sit ea qui enim Lorem.Est in tempor
            et labore.
          </p>
        </div>
        <Container className="container">
          <div className="crousal-style">
            <Carousel
              ssr
              partialVisible
              removeArrowOnDeviceType={[]}
              itemClass="image-item"
              responsive={responsive}
              arrows={true}
              infinite={true}
              showDots={false}
              autoPlay={true}
              autoPlaySpeed={2000}
            >
              {data.map((items) => {
                return (
                  <div className="core-feature-card">
                    <div className="image-wrapper">
                    <img
                      className="banner-brand-image"
                      src={items.image}
                      alt={items.name}
                    />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </Container>
      </div>
    </>
  );
};
